
body {
    background-color: #390113;
    color: #ffffff;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.slider-img {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;

}

input, textarea {
    width: 98%;

}

textarea {
    width: 98%;
    resize: none;


}

.tuur {
    height: 100%;
    position: fixed;
    z-index: 1;
    width: 100%;
    animation: animate 2s forwards !important;
    background-size: 200% 200% !important;
    top: 0;

}

.gevers {
    height: 120vh !important;
    z-index: 2;
    background-color: #574144;
    position: relative;
    top: 206vh;
    width: 105vw;
    display: flex;
    align-items: center;
}

.gevers2 {
    height: 120vh;
    z-index: 3;
    background-color: #574144;
    position: relative;
    top: 206vh;
    width: 100%;
    border-radius: 0 !important;
}

.contact {
    height: 110vh;
    z-index: 6;
    background-color: #574144;
    position: relative;
    top: 205vh;
    width: 100%;
    border-radius: 0 !important;
}

.fixed-contact {
    position: fixed !important;
    height: 100%;
    width: 100%;
    top: 105px;
}

.fixed {
    position: fixed !important;
    height: 100%;
    width: 100%;
    top: 0;

}

.fadein {
    animation-name: fadein !important;
    animation-duration: 2s !important;
    animation-fill-mode: forwards !important;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 2px solid #989595;


}

.nav {
    position: fixed;
    top: 0;
    width: 10%;
    height: 98%;
    display: flex;
    align-items: center;
    z-index: 100;

}

#home {
    display: block;
    position: relative;
    top: -50px;
    visibility: hidden;

}

#about {
    display: block;
    position: relative;
    top: 216vh;
    visibility: hidden;

}

#projects {
    display: block;
    position: relative;
    top: 216vh;
    visibility: hidden;

}

#contact {
    display: block;
    position: relative;
    top: 216vh;
    visibility: hidden;

}


.nav ul {
    list-style-type: none !important;

}

a, a:visited, a:hover {
    text-decoration: none;
    color: #fff;
}

.discover {
    margin-left: auto;
    margin-right: auto;
    width: 18%;
    min-width: 180px;
    text-align: center;
    margin-top: 24px;
    background-color: #a24750;
    padding: 8px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;

}

.project-button {
    min-width: 180px;
    margin-left: 66%;
    margin-right: auto;
    width: 18%;
    text-align: center;
    margin-top: -8vh;
    background-color: #a24750;
    padding: 8px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
}

.contact-button {
    margin-left: 68%;
    margin-right: auto;
    width: 18%;
    text-align: center;
    margin-top: -12vh;
    background-color: #a24750;
    padding: 8px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;

}


.discover:hover, .project-button:hover, .contact-button:hover {
    animation-name: brighten;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes fadein {
    from {
        width: 0%
    }

    to {
        width: 15%
    }
}

@keyframes brighten {
    from {
    }
    to {
        background-color: #7e2229;
        border: 4px solid #fff;
        box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);

    }
}

@keyframes animate {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;

    }

}

@media only screen and (max-width: 900px) {
    .project-button {
        margin-top: 40px;
        margin-left: 5%;
        width: 80%;


    }

    iframe {
        height: 100vh;
        width:100%;
    }

    .gevers2{
        height:350vh !important;
    }

    .contact{
        top: 206vh;
        height:180vh;

    }

    .contact-button{
        margin-top:8vh;
        margin-left: 8%;
        width: 80%;
    }
}
